import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { ITopplisteDeltaker } from '../../../typer';
import DeltakerBilde from '../../../komponenter/DeltakerBilde';
import { useMediaQuery } from 'usehooks-ts';
import { maksBredde } from '../../../konfigurasjoner';

interface deltakerProps {
    deltaker: ITopplisteDeltaker;
    plass: number;
}

const Deltaker = ({ plass, deltaker }: deltakerProps) => {
    const skjermBredde = useMediaQuery(maksBredde);

    return (
        <Flex w="100%" maxW="800px" p={2} gap={5} borderBottom="1px solid var(--overskyet)">
            <Flex w={skjermBredde ? '30%' : '15%'} justify="space-between" align="center">
                <Text className="b-uthevet">{plass}</Text>
                <DeltakerBilde ansattnr={deltaker.ansattnr} w={50} h={50} />
            </Flex>
            <Flex w="100%" justify="space-between" align="center">
                <Text className="b1">{deltaker.navn}</Text>
                <Text className="b">{deltaker.poengTotalt} poeng</Text>
            </Flex>
        </Flex>
    );
};

export default Deltaker;
