import React from 'react';

const Konfetti = () => {
    return (
        <svg
            width="383"
            height="237"
            viewBox="0 0 383 237"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M273.89 68.0369C274.36 67.525 274.925 66.9499 275.524 66.3409C278.558 63.2502 282.715 59.0178 284.514 53.2295C286.674 46.2758 284.819 38.8859 278.999 31.2648C277.989 29.9417 276.102 29.6923 274.785 30.7059C273.468 31.72 273.218 33.614 274.228 34.9368C278.854 40.9944 280.341 46.3902 278.775 51.4319C277.41 55.8225 273.99 59.3053 271.243 62.1035C270.609 62.7486 270.011 63.3575 269.474 63.9416C268.347 65.166 268.423 67.0751 269.642 68.2058C270.197 68.7217 270.925 69.0079 271.681 69.0069C272.096 69.0075 272.506 68.9217 272.887 68.7547C273.267 68.5877 273.608 68.3433 273.89 68.0369Z"
                fill="#16DBC4"
            />
            <path
                d="M329.699 218.863C329.422 218.224 329.126 217.473 328.812 216.678C327.218 212.644 325.035 207.118 320.484 203.133C315.016 198.344 307.532 197.07 298.239 199.344C296.626 199.739 295.638 201.372 296.031 202.991C296.424 204.61 298.05 205.603 299.663 205.208C307.05 203.4 312.568 204.209 316.532 207.68C319.984 210.704 321.78 215.25 323.223 218.903C323.556 219.745 323.87 220.54 324.186 221.269C324.848 222.797 326.619 223.498 328.141 222.833C328.835 222.532 329.389 221.978 329.693 221.283C329.861 220.902 329.948 220.49 329.949 220.073C329.95 219.657 329.865 219.245 329.699 218.863Z"
                fill="#FFF02B"
            />
            <path
                d="M54.3208 58.8277C54.6242 59.6733 54.9427 60.6521 55.2799 61.6887C56.9919 66.9486 59.3358 74.1521 64.9671 80.8619C71.7323 88.9226 81.5131 94.1327 94.0385 96.3473C96.2128 96.732 97.6816 95.433 97.3213 93.4477C96.9602 91.4618 94.9058 89.5406 92.7319 89.1559C82.7762 87.3957 75.5519 83.6988 70.6468 77.8544C66.3753 72.7647 64.4463 66.8374 62.8963 62.0752C62.539 60.9775 62.2019 59.9409 61.8558 58.976C61.1302 56.9537 58.8554 55.2813 56.775 55.2404C55.8269 55.2203 55.0383 55.5493 54.5676 56.1614C54.3078 56.4963 54.1517 56.9062 54.1093 57.3652C54.0668 57.8242 54.1388 58.3222 54.3208 58.8277Z"
                fill="#FF5B5B"
            />
            <path
                d="M89.9445 168.656C89.1492 169.069 88.2242 169.514 87.2447 169.985C82.2745 172.378 75.4677 175.653 69.5785 182.136C62.5038 189.924 58.6387 200.341 58.0908 213.097C57.9955 215.312 59.4705 216.604 61.3839 215.985C63.2977 215.366 64.9261 213.07 65.0215 210.856C65.4569 200.717 68.1622 193.042 73.2918 187.396C77.759 182.478 83.3598 179.783 87.8598 177.617C88.897 177.117 89.8765 176.646 90.7841 176.175C92.6862 175.189 94.0401 172.706 93.8082 170.63C93.704 169.683 93.2758 168.942 92.6098 168.553C92.2451 168.338 91.8198 168.237 91.3611 168.255C90.9023 168.272 90.4199 168.409 89.9445 168.656Z"
                fill="#8E24C9"
            />
            <path
                d="M122.149 229.977C122.669 227.58 122.335 224.924 121.208 222.5C121.192 222.466 121.173 222.435 121.156 222.402C120.175 220.198 120.608 218.018 121.674 217.518C123.239 216.785 123.916 214.917 123.185 213.345C122.454 211.776 120.594 211.095 119.029 211.828C114.565 213.92 113.029 219.776 115.531 225.16C115.545 225.19 115.563 225.217 115.578 225.246C116.089 226.389 116.259 227.621 116.037 228.642C115.943 229.076 115.692 229.848 115.065 230.142C114.439 230.436 113.688 230.133 113.296 229.926C112.351 229.428 111.497 228.473 110.954 227.304C109.827 224.88 108.015 222.917 105.85 221.777C103.44 220.508 100.771 220.382 98.5295 221.432C94.0654 223.523 92.5292 229.379 95.0318 234.764C95.0861 234.881 95.1469 234.993 95.2133 235.1C96.0379 236.428 97.74 236.96 99.1882 236.281C100.754 235.548 101.43 233.679 100.7 232.108C99.6591 229.87 100.092 227.629 101.174 227.122C101.801 226.829 102.552 227.131 102.944 227.338C103.889 227.836 104.743 228.791 105.286 229.96C105.507 230.436 105.755 230.895 106.027 231.333C107.139 233.124 108.65 234.571 110.389 235.487C112.8 236.757 115.469 236.882 117.71 235.832C119.952 234.782 121.57 232.648 122.149 229.977Z"
                fill="#FFF02B"
            />
            <path
                d="M115.656 50.2891C118.685 51.1432 121.637 50.8712 123.968 49.5227C124 49.5038 124.029 49.4803 124.06 49.4599C126.199 48.2928 128.935 49.0161 129.99 50.4759C131.538 52.6194 134.019 53.6484 135.53 52.7746C137.038 51.8997 137.011 49.4542 135.463 47.311C131.047 41.1981 123.531 38.7549 118.353 41.7493C118.324 41.7661 118.3 41.788 118.273 41.8059C117.164 42.4147 115.801 42.56 114.512 42.1965C113.963 42.0418 112.95 41.654 112.33 40.7959C111.711 39.9385 111.732 38.9528 111.799 38.4418C111.963 37.2097 112.7 36.1302 113.824 35.4806C116.157 34.1327 117.646 31.8362 118.019 29.0143C118.435 25.8717 117.402 22.3082 115.185 19.2385C110.768 13.1251 103.253 10.6822 98.0744 13.6762C97.9616 13.7412 97.8575 13.8152 97.762 13.897C96.5759 14.9129 96.7084 17.1561 98.1413 19.1394C99.6895 21.2831 102.17 22.3121 103.681 21.4383C105.834 20.1931 108.641 20.9207 109.712 22.4028C110.331 23.2607 110.31 24.2463 110.242 24.7575C110.079 25.9893 109.342 27.0692 108.218 27.7185C107.759 27.9833 107.334 28.2849 106.943 28.6199C105.344 29.9895 104.323 31.9173 104.023 34.1847C103.607 37.3276 104.64 40.8909 106.857 43.9606C109.075 47.0309 112.282 49.3376 115.656 50.2891Z"
                fill="#FF8034"
            />
            <circle cx="85.9805" cy="64.5" r="2.5" fill="#FF5B5B" />
            <circle cx="82.9805" cy="72.5" r="2.5" fill="#FF8034" />
            <circle cx="92.9805" cy="70.5" r="2.5" fill="#16DBC4" />
            <circle cx="47.9805" cy="165.5" r="2.5" fill="#FF8034" />
            <circle cx="51.9805" cy="146.5" r="2.5" fill="#8E24C9" />
            <circle cx="259.5" cy="36.5" r="2.5" fill="#FF5B5B" />
            <circle cx="262.5" cy="53.5" r="2.5" fill="#FFF02B" />
            <circle cx="293.98" cy="86.5" r="2.5" fill="#16DBC4" />
            <circle cx="313.98" cy="130.5" r="2.5" fill="#FF8034" />
            <circle cx="295.98" cy="123.5" r="2.5" fill="#8E24C9" />
            <circle cx="223.98" cy="32.5" r="2.5" fill="#FF5B5B" />
            <circle cx="211.98" cy="42.5" r="2.5" fill="#FFF02B" />
            <circle cx="230.98" cy="38.5" r="2.5" fill="#16DBC4" />
            <circle
                cx="151.19"
                cy="31.6945"
                r="2.5"
                transform="rotate(-97.7231 151.19 31.6945)"
                fill="#8E24C9"
            />
            <circle
                cx="162.712"
                cy="42.2414"
                r="2.5"
                transform="rotate(-97.7231 162.712 42.2414)"
                fill="#8E24C9"
            />
            <circle
                cx="156.194"
                cy="23.9514"
                r="2.5"
                transform="rotate(-97.7231 156.194 23.9514)"
                fill="#16DBC4"
            />
            <path
                d="M79.2247 121.742C79.3441 119.378 78.6081 116.901 77.1518 114.765C76.3029 113.519 74.6029 113.198 73.359 114.052C72.1155 114.907 71.7976 116.612 72.649 117.86C73.4229 118.995 73.8332 120.309 73.775 121.464C73.7487 121.982 73.5985 122.924 72.8862 123.414C72.174 123.903 71.245 123.703 70.7545 123.54C69.6597 123.177 68.589 122.318 67.8147 121.183C64.6509 116.545 58.9735 114.903 55.1574 117.525C51.3419 120.148 50.8113 126.055 53.9746 130.695C53.9912 130.719 54.0113 130.739 54.0286 130.763C55.5397 133.036 55.3507 135.466 54.2195 136.243C53.5075 136.733 52.5782 136.533 52.0878 136.37C50.9931 136.006 49.9217 135.147 49.148 134.012C45.9848 129.374 40.3067 127.733 36.4907 130.355C32.6752 132.977 32.1445 138.885 35.3079 143.524C35.3852 143.637 35.4694 143.742 35.5597 143.84C36.4635 144.82 37.9699 145.014 39.1006 144.237C40.3439 143.382 40.6619 141.678 39.8107 140.429C38.2464 138.135 38.4301 135.661 39.5732 134.875C40.7167 134.09 43.0815 134.811 44.645 137.107C44.9934 137.618 45.3754 138.098 45.7853 138.542C47.0869 139.953 48.6714 141.005 50.3739 141.571C52.8394 142.39 55.3646 142.095 57.3023 140.764C61.1177 138.141 61.6484 132.234 58.4851 127.594C58.4642 127.564 58.4408 127.537 58.4189 127.507C56.9217 125.239 57.1122 122.821 58.2397 122.046C59.3832 121.26 61.748 121.982 63.3115 124.277C63.6601 124.788 64.0419 125.268 64.4518 125.712C65.7536 127.124 67.3379 128.176 69.0404 128.741C71.5059 129.56 74.0311 129.266 75.9688 127.934C77.9065 126.602 79.0932 124.345 79.2247 121.742Z"
                fill="#16DBC4"
            />
            <path
                d="M341.386 61.9928C341.567 59.6331 340.895 57.1371 339.495 54.9643C338.679 53.6964 336.988 53.3305 335.722 54.1523C334.457 54.9741 334.095 56.6698 334.913 57.94C335.657 59.0947 336.033 60.4188 335.945 61.5726C335.905 62.0897 335.731 63.0274 335.006 63.4981C334.281 63.9689 333.358 63.7444 332.872 63.5687C331.787 63.1767 330.739 62.2896 329.994 61.1348C326.952 56.4159 321.319 54.6257 317.436 57.1469C313.554 59.6682 312.87 65.56 315.911 70.2805C315.927 70.3051 315.947 70.3258 315.964 70.3498C317.415 72.662 317.163 75.0861 316.012 75.8336C315.287 76.3041 314.364 76.0798 313.878 75.9041C312.793 75.5119 311.744 74.6255 311 73.4702C307.959 68.7511 302.325 66.9611 298.442 69.4823C294.56 72.0036 293.876 77.8954 296.917 82.6159C296.992 82.7308 297.073 82.8387 297.161 82.9389C298.039 83.942 299.54 84.175 300.69 83.4279C301.955 82.6062 302.318 80.9106 301.499 79.6402C299.995 77.3059 300.243 74.8374 301.406 74.0822C302.57 73.3267 304.915 74.1096 306.418 76.4458C306.753 76.9657 307.123 77.4554 307.521 77.9103C308.785 79.3549 310.342 80.4487 312.029 81.0585C314.472 81.9417 317.004 81.7138 318.976 80.4333C322.858 77.912 323.543 72.0202 320.501 67.2997C320.481 67.2687 320.458 67.2407 320.437 67.2106C318.999 64.9045 319.253 62.4919 320.4 61.7466C321.564 60.9911 323.909 61.7748 325.412 64.1101C325.747 64.6303 326.116 65.1198 326.515 65.5747C327.779 67.0195 329.336 68.1131 331.023 68.7229C333.466 69.6061 335.998 69.3782 337.97 68.0977C339.942 66.8172 341.187 64.5921 341.386 61.9928Z"
                fill="#8E24C9"
            />
            <path
                d="M348.983 179.81C351.008 178.598 352.652 176.608 353.613 174.206C354.174 172.806 353.497 171.208 352.099 170.643C350.7 170.079 349.111 170.76 348.549 172.164C348.039 173.44 347.178 174.512 346.188 175.105C345.744 175.37 344.88 175.767 344.079 175.444C343.278 175.121 342.929 174.234 342.791 173.734C342.485 172.618 342.603 171.247 343.113 169.971C345.2 164.755 343.409 159.102 339.119 157.371C334.828 155.64 329.639 158.475 327.551 163.692C327.54 163.719 327.534 163.747 327.524 163.775C326.479 166.298 324.361 167.489 323.089 166.976C322.288 166.653 321.938 165.766 321.801 165.266C321.495 164.15 321.612 162.779 322.123 161.502C324.21 156.287 322.419 150.634 318.128 148.902C313.838 147.171 308.648 150.007 306.56 155.223C306.509 155.351 306.469 155.48 306.438 155.609C306.127 156.908 306.803 158.273 308.075 158.786C309.473 159.35 311.062 158.67 311.624 157.266C312.656 154.687 314.808 153.467 316.093 153.985C317.379 154.504 318.094 156.879 317.06 159.459C316.829 160.034 316.644 160.619 316.503 161.208C316.057 163.078 316.064 164.986 316.54 166.721C317.23 169.235 318.875 171.18 321.054 172.059C325.345 173.79 330.534 170.955 332.623 165.738C332.636 165.704 332.646 165.669 332.658 165.634C333.706 163.125 335.816 161.942 337.084 162.454C338.37 162.972 339.084 165.348 338.05 167.928C337.82 168.502 337.634 169.087 337.494 169.676C337.047 171.547 337.054 173.454 337.531 175.189C338.221 177.703 339.866 179.648 342.045 180.527C344.224 181.406 346.752 181.145 348.983 179.81Z"
                fill="#FF5B5B"
            />
            <path
                d="M221.244 18.7035C220.713 16.3086 219.307 14.0773 217.286 12.4211C217.257 12.3979 217.227 12.3787 217.198 12.3568C215.4 10.83 214.881 8.67099 215.631 7.72883C216.732 6.34537 216.562 4.35329 215.252 3.27964C213.942 2.20817 211.987 2.45701 210.886 3.84023C207.745 7.78577 208.802 13.7586 213.292 17.438C213.317 17.4585 213.344 17.4745 213.37 17.4939C214.305 18.2847 214.97 19.3133 215.196 20.3324C215.292 20.7661 215.388 21.5767 214.947 22.1306C214.507 22.6841 213.705 22.7598 213.267 22.7558C212.211 22.7458 211.046 22.2827 210.072 21.4839C208.05 19.8266 205.605 18.9024 203.186 18.8801C200.491 18.8548 198.041 19.977 196.464 21.958C193.323 25.9037 194.38 31.8762 198.87 35.5559C198.968 35.636 199.069 35.7087 199.173 35.7741C200.467 36.5861 202.218 36.2757 203.236 34.9955C204.338 33.6122 204.168 31.6202 202.858 30.5465C200.991 29.0166 200.448 26.8032 201.209 25.8467C201.65 25.2931 202.451 25.2173 202.889 25.2215C203.945 25.2314 205.11 25.6947 206.084 26.4932C206.481 26.819 206.895 27.116 207.322 27.3838C209.066 28.4784 211.027 29.0793 212.97 29.097C215.665 29.1224 218.115 28.0001 219.692 26.0191C221.269 24.0376 221.835 21.3712 221.244 18.7035Z"
                fill="#FFF02B"
            />
            <circle cx="92.9805" cy="125.5" r="2.5" fill="#FF8034" />
            <circle cx="83.9805" cy="120.5" r="2.5" fill="#16DBC4" />
            <circle cx="313.5" cy="110.5" r="2.5" fill="#16DBC4" />
            <path
                d="M329.828 103.997C330.64 103.708 331.579 103.407 332.574 103.089C337.62 101.475 344.531 99.2649 351.093 93.5723C358.976 86.7337 364.266 76.6141 366.817 63.494C367.26 61.2164 366.082 59.6216 364.188 59.9297C362.293 60.2386 360.398 62.3343 359.955 64.6114C357.928 75.0398 354.164 82.5198 348.448 87.4782C343.471 91.7961 337.784 93.6151 333.215 95.0767C332.162 95.4137 331.167 95.7316 330.241 96.0614C328.298 96.7531 326.631 99.0901 326.517 101.281C326.464 102.28 326.747 103.122 327.31 103.641C327.618 103.926 328.001 104.106 328.435 104.167C328.868 104.228 329.342 104.17 329.828 103.997Z"
                fill="#FF5B5B"
            />
            <circle cx="28.9805" cy="95.5" r="2.5" fill="#FF8034" />
            <circle cx="31.9805" cy="79.5" r="2.5" fill="#16DBC4" />
            <path
                d="M69.2689 13.3615C66.9376 13.4323 64.5839 14.4395 62.6415 16.1981C62.6143 16.2227 62.5904 16.25 62.5644 16.2755C60.7867 17.8296 58.6771 17.9346 57.9513 16.9717C56.8855 15.5578 55.0005 15.3358 53.7415 16.4755C52.4845 17.6153 52.3256 19.6857 53.3912 21.0994C56.4309 25.1315 62.1918 25.2311 66.5065 21.3256C66.5306 21.3037 66.5508 21.279 66.5739 21.2565C67.4954 20.4477 68.5838 19.9658 69.5759 19.9358C69.998 19.923 70.7702 19.9861 71.1971 20.552C71.6235 21.1175 71.534 21.9605 71.4428 22.4125C71.2227 23.5021 70.5602 24.6131 69.6234 25.4606C67.6801 27.219 66.3335 29.5615 65.8301 32.0569C65.269 34.8363 65.8229 37.5919 67.349 39.6168C70.3887 43.6493 76.1494 43.7487 80.4643 39.8434C80.5583 39.7585 80.646 39.6683 80.7276 39.5737C81.7402 38.3988 81.801 36.5279 80.8148 35.2197C79.7492 33.8056 77.8642 33.5836 76.6052 34.7233C74.8111 36.3473 72.646 36.4671 71.9091 35.4895C71.4827 34.9236 71.572 34.0808 71.6634 33.6287C71.8833 32.5392 72.5462 31.4278 73.4826 30.5807C73.8645 30.2353 74.223 29.8671 74.5569 29.4797C75.9221 27.8957 76.8716 25.9895 77.2759 23.9844C77.8372 21.2049 77.2831 18.4494 75.757 16.4245C74.2304 14.3992 71.8657 13.2827 69.2689 13.3615Z"
                fill="#FFF02B"
            />
            <circle
                cx="89.0425"
                cy="205.563"
                r="2.5"
                transform="rotate(91.4627 89.0425 205.563)"
                fill="#FF8034"
            />
            <circle
                cx="83.0425"
                cy="185.563"
                r="2.5"
                transform="rotate(91.4627 83.0425 185.563)"
                fill="#FF5B5B"
            />
            <circle
                cx="306.955"
                cy="177.5"
                r="2.5"
                transform="rotate(144.218 306.955 177.5)"
                fill="#16DBC4"
            />
            <circle
                cx="318.491"
                cy="186.49"
                r="2.5"
                transform="rotate(144.218 318.491 186.49)"
                fill="#FF8034"
            />
            <circle
                cx="288.491"
                cy="190.49"
                r="2.5"
                transform="rotate(144.218 288.491 190.49)"
                fill="#8E24C9"
            />
            <circle cx="279.98" cy="13.5" r="2.5" fill="#FF5B5B" />
            <circle cx="272.98" cy="28.5" r="2.5" fill="#FF8034" />
            <circle cx="310.98" cy="21.5" r="2.5" fill="#16DBC4" />
            <path
                d="M34.5974 173.961C36.3973 172.116 37.3646 170.08 37.3205 168.229C37.3198 168.203 37.3142 168.179 37.3118 168.154C37.3217 166.47 38.9133 164.792 40.3683 164.4C42.5047 163.824 44.2136 162.384 44.1853 161.184C44.1551 159.985 42.4017 159.478 40.2656 160.053C34.1727 161.696 29.4757 166.205 29.5727 170.317C29.5734 170.34 29.5797 170.361 29.5817 170.383C29.5793 171.257 29.1467 172.201 28.3807 172.986C28.0548 173.32 27.38 173.916 26.5247 174.146C25.67 174.377 24.9759 174.151 24.6384 173.995C23.8247 173.621 23.3454 172.895 23.3247 172.002C23.2815 170.15 22.2308 168.658 20.3667 167.801C18.291 166.846 15.3453 166.771 12.2858 167.596C6.1925 169.239 1.49586 173.747 1.59251 177.86C1.5945 177.95 1.60626 178.035 1.62699 178.117C1.88439 179.13 3.53501 179.524 5.51184 178.991C7.64834 178.415 9.35723 176.975 9.32887 175.775C9.28846 174.065 10.9109 172.341 12.3881 171.943C13.2431 171.712 13.9371 171.938 14.2747 172.094C15.0884 172.468 15.5677 173.195 15.5884 174.087C15.5967 174.451 15.6444 174.801 15.7293 175.135C16.0763 176.501 17.0484 177.6 18.5463 178.288C20.6221 179.243 23.5678 179.318 26.6273 178.493C29.6875 177.668 32.5924 176.016 34.5974 173.961Z"
                fill="#FF8034"
            />
            <path
                d="M371.38 123.894C373.01 121.929 373.836 119.695 373.705 117.605C373.703 117.575 373.696 117.548 373.693 117.52C373.621 115.621 375.061 113.832 376.431 113.483C378.444 112.97 380.006 111.455 379.921 110.1C379.835 108.747 378.137 108.062 376.124 108.575C370.386 110.038 366.117 114.821 366.407 119.465C366.409 119.491 366.416 119.514 366.419 119.539C366.458 120.524 366.091 121.561 365.397 122.398C365.102 122.754 364.486 123.382 363.68 123.587C362.875 123.792 362.202 123.493 361.872 123.296C361.077 122.822 360.585 121.973 360.522 120.965C360.392 118.873 359.317 117.125 357.496 116.039C355.468 114.83 352.652 114.557 349.77 115.292C344.031 116.755 339.762 121.538 340.052 126.181C340.058 126.283 340.074 126.38 340.097 126.473C340.392 127.632 341.987 128.181 343.849 127.707C345.861 127.194 347.424 125.679 347.339 124.324C347.218 122.394 348.685 120.554 350.076 120.199C350.882 119.994 351.555 120.293 351.885 120.49C352.68 120.964 353.172 121.814 353.235 122.822C353.26 123.233 353.323 123.63 353.42 124.012C353.817 125.574 354.798 126.875 356.261 127.747C358.289 128.957 361.105 129.229 363.987 128.494C366.869 127.76 369.564 126.083 371.38 123.894Z"
                fill="#FF8034"
            />
        </svg>
    );
};

export default Konfetti;
