import React from 'react';
import { TableContainer, Table, Thead, Tr, Th, Tbody, Flex } from '@chakra-ui/react';
import { IDeltaker } from '../../../typer';
import DeltakerRad from './DeltakerRad';

interface Props {
    deltakere: IDeltaker[];
    utfordringId: number;
    oppdaterDeltakerliste: (oppdaterDeltaker: IDeltaker, indeks: number) => void;
}

const DeltakerTabell = ({ deltakere, utfordringId, oppdaterDeltakerliste }: Props) => {
    return (
        <Flex pb={100}>
            <TableContainer w={'800px'}>
                <Table>
                    <Thead>
                        <Tr>
                            <Th>Navn</Th>
                            <Th>Poeng</Th>
                            <Th>Bonuspoeng</Th>
                            <Th />
                        </Tr>
                    </Thead>
                    <Tbody>
                        {deltakere.map((deltaker, indeks) => (
                            <DeltakerRad
                                deltaker={deltaker}
                                key={indeks}
                                utfordringId={utfordringId}
                                oppdaterDeltakerliste={(oppdatertDeltaker: IDeltaker) =>
                                    oppdaterDeltakerliste(oppdatertDeltaker, indeks)
                                }
                            />
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
        </Flex>
    );
};

export default DeltakerTabell;
