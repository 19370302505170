import { supabase } from './supabaseClient';

export const erAdmin = async (ansattnr: number): Promise<boolean> => {
    try {
        const { data, error } = await supabase.from('admin').select('*').eq('ansattnr', ansattnr);

        if (error) throw error;

        return data.length > 0;
    } catch (error) {
        console.log(error);
    }

    return false;
};
