import { useAuth0 } from '@auth0/auth0-react';

const useAnsattnummer = () => {
    const { getIdTokenClaims } = useAuth0();

    const hentAnsattnummer = async (): Promise<number> => {
        try {
            const claims = await getIdTokenClaims();
            const employeeId = claims && claims['https://api.bekk.no/claims/employeeId'];
            return employeeId;
        } catch (error) {
            throw new Error("Noe gikk galt da vi forsøkte å hente ansattnummer fra id_token'et");
        }
    };

    return hentAnsattnummer();
};

export default useAnsattnummer;
