import React from 'react';
import { Link as ReactRouterLink, useLocation } from 'react-router-dom';
import { Text, Link } from '@chakra-ui/react';

type Props = {
    href: string;
    tekst: string;
};

const NavbarKnapp = ({ href, tekst }: Props) => {
    const location = useLocation();

    return (
        <Link as={ReactRouterLink} to={href}>
            <Text className={href === location.pathname ? 'navbar-tekst-valgt' : 'navbar-tekst'}>
                {tekst}
            </Text>
        </Link>
    );
};

export default NavbarKnapp;
