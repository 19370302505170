import React from 'react';
import { Flex, Link, Text } from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';

import PrimarKnapp from '../../../komponenter/PrimarKnapp';

type Props = {
    tekst: string;
    path?: string;
    knappTekst?: string;
};

const FinnesIkke = ({ path, tekst, knappTekst }: Props) => {
    return (
        <Flex direction="column" align="center" justify="center" h="100vh" gap={10}>
            <Text align="center" as="h2">
                {tekst}
            </Text>
            {knappTekst !== undefined && path !== undefined && (
                <Link as={ReactRouterLink} to={path}>
                    <PrimarKnapp tekst={knappTekst} />
                </Link>
            )}
        </Flex>
    );
};

export default FinnesIkke;
