import { IAnsatt, IRestDeltakelse } from '../typer';
import { supabase } from './supabaseClient';
import { get } from './api';

interface AnsattResponseType {
    id: number;
    name: string;
    [key: string]: unknown;
}

export const hentAlleAnsatte = (token: string): Promise<IAnsatt[]> =>
    get({
        path: '/v3/employees?IncludeNotStarted=false&IncludeResigned=false',
        token,
    }).then((res) =>
        res.map((ansatt: AnsattResponseType) => {
            return {
                ansattnr: ansatt.id,
                navn: ansatt.name,
            };
        })
    );

export const registrerDeltakelse = async (
    nyeDeltakere: IRestDeltakelse[]
): Promise<IRestDeltakelse[] | undefined> => {
    try {
        const { data, error } = await supabase.from('deltakelse').upsert(nyeDeltakere).select();

        if (error) throw error;

        return data;
    } catch (error) {
        console.log('error: ', error);
    }
};

export const hentDeltakelse = async (
    utfordringId: number
): Promise<IRestDeltakelse[] | undefined> => {
    try {
        const { data, error } = await supabase
            .from('deltakelse')
            .select('*')
            .eq('utfordringId', utfordringId);

        if (error) throw error;

        return data;
    } catch (error) {
        console.log(error);
    }
};

export const redigerPoeng = async (
    poeng: number,
    bonuspoeng: number,
    ansattnr: number,
    utfordringId: number
): Promise<IRestDeltakelse | undefined> => {
    try {
        const { data, error } = await supabase
            .from('deltakelse')
            .update({
                poeng: poeng,
                bonuspoeng: bonuspoeng,
            })
            .eq('utfordringId', utfordringId)
            .eq('ansattnr', ansattnr)
            .select()
            .single();

        if (error) throw error;

        return data;
    } catch (error) {
        console.log(error);
        alert('Error bitch!');
    }
};
