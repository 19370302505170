import { useAuth0 } from '@auth0/auth0-react';
import createUseContext from 'constate';
import { useEffect, useState } from 'react';
import { hentAlleAnsatte } from '../api/deltakerApi';
import { IAnsatt, IAnsattSelectOption, IDeltaker, IRestDeltakelse } from '../typer';

const [AnsatteProvider, useAnsatte] = createUseContext(() => {
    const [alleAnsatte, settAlleAnsatte] = useState<IAnsatt[]>([]);
    const ansattIndeksertPåAnsattnr = new Map<number, number>();
    const auth0 = useAuth0();

    useEffect(() => {
        const hentData = async () => {
            /*TODO: Snakk med basen og få endepunktet til å håndtere access tokens, og ikke idTokens */
            const idToken = await auth0
                .getIdTokenClaims()
                .then((idTokenClaims) => idTokenClaims?.__raw);

            const ansatte = await hentAlleAnsatte(idToken || '');

            if (ansatte) {
                settAlleAnsatte(ansatte);
            }
        };
        hentData();
    }, []);

    // Lag Map<ansattnr, indeks> for å lett kunne slå opp navn
    alleAnsatte.forEach((ansatt, indeks) => {
        ansattIndeksertPåAnsattnr.set(ansatt.ansattnr, indeks);
    });

    const finnNavnPåAnsatt = (ansattnr: number) => {
        const indeks = ansattIndeksertPåAnsattnr.get(ansattnr);
        if (indeks !== undefined) {
            return alleAnsatte[indeks].navn;
        }
    };

    const finnNavnPåAnsattSomDeltaker = (deltaker: IRestDeltakelse): IDeltaker | undefined => {
        const indeks = ansattIndeksertPåAnsattnr.get(deltaker.ansattnr);
        if (indeks !== undefined) {
            const ansatt = alleAnsatte[indeks];

            return {
                navn: ansatt.navn,
                ansattnr: ansatt.ansattnr,
                poeng: deltaker.poeng,
                bonuspoeng: deltaker.bonuspoeng,
            };
        }
    };

    const finnNavnPåAnsatte = (deltakere: IRestDeltakelse[]): IDeltaker[] => {
        const deltakereMedNavn: IDeltaker[] = [];
        deltakere.forEach((deltaker) => {
            const deltakerMedNavn = finnNavnPåAnsattSomDeltaker(deltaker);

            if (deltakerMedNavn) deltakereMedNavn.push(deltakerMedNavn);
        });

        return deltakereMedNavn;
    };

    const hentAnsatteSomSelectOptions = (deltakere: IDeltaker[]): IAnsattSelectOption[] => {
        const registrerteDeltakere = deltakere.map((d) => d.ansattnr);

        const deltakereSelectOptions = alleAnsatte.filter(
            (ansatt) => !registrerteDeltakere.includes(ansatt.ansattnr)
        );

        return deltakereSelectOptions.map((ansatt) => {
            return {
                label: ansatt.navn,
                value: ansatt.ansattnr,
            };
        });
    };

    return {
        alleAnsatte,
        hentAnsatteSomSelectOptions,
        finnNavnPåAnsatte,
        finnNavnPåAnsattSomDeltaker,
        finnNavnPåAnsatt,
    };
});

export { AnsatteProvider, useAnsatte };
