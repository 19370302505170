import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import Deltaker from './komponenter/Deltaker';
import LedendeDeltaker from './komponenter/LedendeDeltaker';
import { ITopplisteDeltaker } from '../../typer';

const TopplisteTotal: FC<{ toppliste: ITopplisteDeltaker[] }> = ({ toppliste }) => {
    return (
        <Flex direction="column" w="100vw" align="center" px="5vw">
            {toppliste.map((deltaker, indeks) =>
                indeks === 0 ? (
                    <LedendeDeltaker deltaker={deltaker} key={indeks} />
                ) : (
                    <Deltaker deltaker={deltaker} plass={deltaker.rank} key={deltaker.ansattnr} />
                )
            )}
        </Flex>
    );
};

export default TopplisteTotal;
