import React, { FC } from 'react';
import {
    Flex,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Text,
    Tooltip,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { ITopplisteDeltaker, IUtfordring } from '../../typer';
import { hentAlleUtfordringer } from '../../api/utfordringerApi';

const TopplisteUtvidet: FC<{ toppliste: ITopplisteDeltaker[]; valgtSesong: string }> = ({
    toppliste,
    valgtSesong,
}) => {
    const [alleUtfordringer, settAlleUtfordringer] = useState<IUtfordring[]>();
    const [utfordringerISesong, settUtfordringerISesong] = useState<IUtfordring[]>();

    useEffect(() => {
        const hentData = async () => {
            const utfordringer = await hentAlleUtfordringer();

            if (utfordringer) {
                settAlleUtfordringer(utfordringer);
            }
        };

        hentData();
    }, []);

    useEffect(() => {
        if (alleUtfordringer) {
            settUtfordringerISesong(
                alleUtfordringer.filter((utfordring) => utfordring.sesong === valgtSesong)
            );
        }
    }, [alleUtfordringer, valgtSesong]);

    return (
        <Flex direction="column" w="100vw" align="center" px="5vw" pb={20}>
            <Text as="h2">UTVIDET TOPPLISTE</Text>
            <TableContainer mt={10}>
                <Table>
                    <Thead>
                        <Tr>
                            <Th>Plass</Th>
                            <Th>Poeng</Th>
                            <Th>Utfordrer</Th>
                            <Th>Deltakelser</Th>
                            {utfordringerISesong?.map((utfordring, indeks) => (
                                <Th key={indeks}>{utfordring.tittel}</Th>
                            ))}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {toppliste.map((deltaker: ITopplisteDeltaker, indeks) => (
                            <Tr key={indeks}>
                                <Td textAlign="center">{deltaker.rank}</Td>
                                <Td textAlign="center">{deltaker.poengTotalt}</Td>
                                <Td>{deltaker.navn}</Td>
                                <Td textAlign="center">{deltaker.antallUtfordringer}</Td>
                                {utfordringerISesong?.map((utfordring, indeks2) =>
                                    deltaker.innsatser[utfordring.id] ? (
                                        <Td
                                            key={indeks2}
                                            justifyContent="space-between"
                                            textAlign="center"
                                        >
                                            <Tooltip
                                                label={
                                                    <Flex flexDir="column">
                                                        <Text>
                                                            Rank:{' '}
                                                            {
                                                                deltaker.innsatser[utfordring.id]
                                                                    .utfordringRank
                                                            }
                                                        </Text>
                                                        <Text>
                                                            Poeng:{' '}
                                                            {
                                                                deltaker.innsatser[utfordring.id]
                                                                    .poeng
                                                            }
                                                        </Text>
                                                        <Text>
                                                            Bonuspoeng:{' '}
                                                            {
                                                                deltaker.innsatser[utfordring.id]
                                                                    .bonuspoeng
                                                            }
                                                        </Text>
                                                    </Flex>
                                                }
                                            >
                                                <Text>
                                                    {
                                                        deltaker.innsatser[utfordring.id]
                                                            .utfordringRank
                                                    }
                                                </Text>
                                            </Tooltip>
                                        </Td>
                                    ) : (
                                        <Td textAlign="center"> - </Td>
                                    )
                                )}
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
        </Flex>
    );
};

export default TopplisteUtvidet;
