import React from 'react';

const HamburgerMeny = () => {
    return (
        <svg viewBox="0 0 100 100">
            <g stroke="#FFFFFF" strokeWidth="1">
                <circle cx="50" cy="50" r="50" fill="#FFFFFF"></circle>
                <g stroke="#000000" className="lineContainer">
                    <line
                        x1="25"
                        y1="37.5"
                        x2="75"
                        y2="37.5"
                        strokeWidth="2.2222"
                        className="topLine"
                    ></line>
                    <line
                        x1="25"
                        y1="50"
                        x2="75"
                        y2="50"
                        strokeWidth="2.2222"
                        className="middleLine"
                    ></line>
                    <line
                        x1="25"
                        y1="62.5"
                        x2="75"
                        y2="62.5"
                        strokeWidth="2.2222"
                        className="bottomLine"
                    ></line>
                </g>
            </g>
        </svg>
    );
};

export default HamburgerMeny;
