import { Flex, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { hentTidligereUtfordringer } from '../../api/utfordringerApi';
import { IUtfordring } from '../../typer';
import TidligereUtfordringer from './komponenter/TidligereUtfordringer';
import { useMediaQuery } from 'usehooks-ts';
import { maksBredde } from '../../konfigurasjoner';

const Utfordringer = () => {
    const skjermBredde = useMediaQuery(maksBredde);

    const [tidligereUtfordringer, settTidligereUtfordringer] = useState<IUtfordring[]>();

    useEffect(() => {
        const hentData = async () => {
            const tidligereUtfordringer = await hentTidligereUtfordringer();
            settTidligereUtfordringer(tidligereUtfordringer);
        };

        hentData();
    }, []);

    return (
        <Flex direction="column" w={skjermBredde ? '85vw' : '50vw'} m="auto" mt="20">
            <Flex direction="column" align="center">
                <Text as="h3">Tar du</Text>
                <Text as="h1">UTFORDRINGEN?</Text>
            </Flex>
            {tidligereUtfordringer !== undefined && (
                <TidligereUtfordringer tidligereUtfordringer={tidligereUtfordringer} />
            )}
        </Flex>
    );
};

export default Utfordringer;
