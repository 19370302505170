export const maksBredde = '(max-width: 720px)';

export const soloppgang = '#ffb88d';
export const soloppgangKontrast = '#ff8034';

export const skyfritt = '#b1e8ff';
export const skyfrittKontrast = '#43cbff';

export const solnedgang = '#ff9999';
export const solnedgangKontrast = '#ff5b5b';

export const kveld = '#e5b1ff';
export const kveldKontrast = '#8e24c9';

export const natt = '#6d7abb';
export const nattKontrast = '#162365';

export const regn = '#bccedd';
export const regnKontrast = '#7e9cb9';

export const overskyet = '#e7e7e7';
export const overskyetKontrast = '#cecece';

export const sol = '#fff2ad';
export const solKontrast = '#fff02b';

export const gronn = '#a1f5e3';
export const gronnKontrast = '#16dbc4';

export const svart = '#0e0e0e';
export const hvit = '#ffffff';
