import React, { useEffect, useState } from 'react';
import { Flex, Link, Text } from '@chakra-ui/react';
import { hentAlleUtfordringer } from '../../../api/utfordringerApi';
import { IUtfordring } from '../../../typer';
import { useMediaQuery } from 'usehooks-ts';
import { maksBredde } from '../../../konfigurasjoner';
import { Link as ReactRouterLink } from 'react-router-dom';
import FinnesIkke from '../komponenter/FinnesIkke';

const AlleUtfordringerAdmin = () => {
    const mobilVisning = useMediaQuery(maksBredde);

    const [alleUtfordringer, settAlleUtfordringer] = useState<IUtfordring[]>();

    useEffect(() => {
        const hentData = async () => {
            const alleUtfordringer = await hentAlleUtfordringer();
            settAlleUtfordringer(alleUtfordringer);
        };

        hentData();
    }, []);

    return (
        <Flex justify="center" py={10}>
            {alleUtfordringer !== undefined ? (
                <Flex direction="column" w={mobilVisning ? '90%' : '50%'}>
                    <Text as="h3" alignSelf="center">
                        Alle Utfordringer
                    </Text>
                    {alleUtfordringer.map((utfordring, indeks) => (
                        <Link
                            key={indeks}
                            as={ReactRouterLink}
                            to={`/admin/utfordring/${utfordring.id}`}
                            variant="boks"
                            my={2}
                        >
                            <Flex
                                w="100%"
                                h="8vh"
                                align="center"
                                justify="space-between"
                                px="5%"
                                border="solid grey 1px"
                                borderRadius={5}
                            >
                                <Flex gap={5}>
                                    <Text className="b2">{utfordring.dato}</Text>
                                    <Text className="b">{utfordring.tittel}</Text>
                                </Flex>

                                <Text className="b2">{utfordring.sted}</Text>
                            </Flex>
                        </Link>
                    ))}
                </Flex>
            ) : (
                <FinnesIkke tekst={'Ingen utfodringer registrert'} />
            )}
        </Flex>
    );
};

export default AlleUtfordringerAdmin;
