import { Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { IUtfordring } from '../../../typer';

interface Props {
    tidligereUtfordringer: IUtfordring[];
}

const TidligereUtfordringer = ({ tidligereUtfordringer }: Props) => {
    return (
        <Flex direction="column" mt="10">
            <Text as="h4" mb={5}>
                Tidligere utfordringer:
            </Text>
            {tidligereUtfordringer.map((utfordring, indeks) => (
                <Flex
                    key={indeks}
                    w="100%"
                    h="8vh"
                    align="center"
                    justify="space-between"
                    px="2%"
                    border="solid grey 1px"
                    borderRadius={5}
                    mb={5}
                >
                    <Flex gap={4.5} w="60%" align="center">
                        <Text className="b2" w="40%">
                            {utfordring.dato}
                        </Text>
                        <Text className="b" w="60%">
                            {utfordring.tittel}
                        </Text>
                    </Flex>

                    <Text className="b2" w="40%" textAlign="right">
                        {utfordring.sted}
                    </Text>
                </Flex>
            ))}
        </Flex>
    );
};

export default TidligereUtfordringer;
