import { FormControl, FormLabel, Input } from '@chakra-ui/react';
import React from 'react';

interface Props {
    label: string;
    name: string;
    value: string;
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const DatoInputfelt = ({ label, name, value, handleChange }: Props) => {
    return (
        <FormControl mt="10">
            <FormLabel className="b1">{label}</FormLabel>
            <Input
                className="b2"
                type="date"
                name={name}
                value={value}
                onChange={(e) => handleChange(e)}
            />
        </FormControl>
    );
};

export default DatoInputfelt;
