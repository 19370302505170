import React, { useEffect, useState } from 'react';

import { Text, Flex, Link } from '@chakra-ui/react';
import { Link as ReactRouterLink, useParams } from 'react-router-dom';
import PrimarKnapp from '../../../komponenter/PrimarKnapp';
import { hentUtfordring } from '../../../api/utfordringerApi';
import { IAnsattSelectOption, IDeltaker, IRestDeltakelse, IUtfordring } from '../../../typer';
import FinnesIkke from '../komponenter/FinnesIkke';
import DeltakerTabell from './DeltakerTabell';
import { hentDeltakelse, registrerDeltakelse } from '../../../api/deltakerApi';
import Select, { MultiValue } from 'react-select';
import { useAnsatte } from '../../../context/AnsatteContext';

const UtfordringAdmin = () => {
    const params = useParams();

    if (params.id === undefined) return null;

    const utfordringId = parseInt(params.id);
    const [utfordring, settUtfordring] = useState<IUtfordring>();
    const [deltakere, settDeltakere] = useState<IDeltaker[]>([]);
    const [nyeDeltakere, settNyeDeltakere] = useState<IAnsattSelectOption[]>([]);

    const { alleAnsatte, hentAnsatteSomSelectOptions, finnNavnPåAnsatte } = useAnsatte();

    const ansatteSomSelectOptions = hentAnsatteSomSelectOptions(deltakere);

    useEffect(() => {
        const hentData = async () => {
            const utfordring = await hentUtfordring(utfordringId);
            settUtfordring(utfordring);

            const deltakereUtenNavn = await hentDeltakelse(utfordringId);

            if (deltakereUtenNavn) {
                settDeltakere(finnNavnPåAnsatte(deltakereUtenNavn));
            }
        };

        hentData();
    }, [utfordringId, alleAnsatte]);

    if (!utfordring) {
        return <FinnesIkke tekst={`Utfordringen med id ${utfordringId} eksisterer ikke 😢`} />;
    }

    const registrerDeltaker = async () => {
        const formaterteNyeDeltakere = formaterOptionsTilDeltakerUtfordring(nyeDeltakere);

        const regDeltakere = await registrerDeltakelse(formaterteNyeDeltakere);

        const regdeltakereansattnr = regDeltakere?.map((d) => d.ansattnr);

        const sjekkRegDeltakere = deltakere.filter(
            (ansatt) => !regdeltakereansattnr?.includes(ansatt.ansattnr)
        );

        if (regDeltakere && sjekkRegDeltakere !== null) {
            settDeltakere([...deltakere, ...finnNavnPåAnsatte(regDeltakere)]);
        }
    };

    const formaterOptionsTilDeltakerUtfordring = (
        options: IAnsattSelectOption[]
    ): IRestDeltakelse[] => {
        return options.map((option) => {
            return {
                ansattnr: option.value,
                utfordringId: utfordringId,
                poeng: 0,
                bonuspoeng: 0,
            };
        });
    };

    const oppdaterDeltakerliste = (oppdatertDeltaker: IDeltaker, indeks: number) => {
        const oppdaterteDeltakere = [...deltakere];
        oppdaterteDeltakere[indeks] = oppdatertDeltaker;
        settDeltakere(oppdaterteDeltakere);
    };

    return (
        <Flex w="100vw" h="100vh">
            <Flex w="100%" h="100%" px="5vw" py="3vh" direction="column" align="center">
                <Flex w="100%" justify="right" align="center" pb={5}>
                    <Link as={ReactRouterLink} to={`/admin/utfordring/${utfordringId}/rediger`}>
                        <PrimarKnapp tekst={'Rediger utfordring'} />
                    </Link>
                </Flex>
                <Text as="h3">{utfordring.tittel}</Text>
                <Flex gap={10} pb={10}>
                    <Text className="b2">{utfordring.dato}</Text>
                    <Text className="b2">{utfordring.sted}</Text>
                </Flex>
                <Text className="b" pb={20}>
                    {utfordring.beskrivelse}
                </Text>

                <Flex color="black" w="100%" gap={2} justify="center" pb={10}>
                    <Select
                        value={nyeDeltakere}
                        placeholder="Velg deltaker"
                        isMulti
                        closeMenuOnSelect={false}
                        options={ansatteSomSelectOptions}
                        isSearchable={true}
                        onChange={(liste: MultiValue<IAnsattSelectOption>) =>
                            settNyeDeltakere([...liste])
                        }
                    />
                    <PrimarKnapp color="white" onClick={registrerDeltaker} tekst={'Legg til'} />
                </Flex>
                {deltakere !== undefined ? (
                    <DeltakerTabell
                        deltakere={deltakere}
                        utfordringId={utfordringId}
                        oppdaterDeltakerliste={oppdaterDeltakerliste}
                    />
                ) : (
                    <Text>Ingen deltakere å vise</Text>
                )}
            </Flex>
        </Flex>
    );
};

export default UtfordringAdmin;
