import { extendTheme } from '@chakra-ui/react';
import { menuTheme } from './menutheme';

const theme = extendTheme({
    components: {
        Menu: menuTheme,
        Link: {
            baseStyle: {
                _hover: {
                    textDecoration: 'none',
                },
            },
            variants: {
                boks: {
                    borderRadius: 5,

                    _hover: {
                        bgColor: 'var(--overskyet-kontrast)',
                        color: 'var(--svart)',
                    },
                    _active: {
                        bgColor: 'var(--overskyet)',
                        color: 'var(--svart)',
                    },
                },
            },
        },
        Button: {
            variants: {
                primær: {
                    borderRadius: 50,
                    border: 'var(--hvit) solid 0.5px',
                    py: '8px',
                    px: '25px',
                    _hover: {
                        bgColor: 'var(--overskyet-kontrast)',
                        color: 'var(--svart)',
                    },
                    _active: {
                        bgColor: 'var(--overskyet)',
                        color: 'var(--svart)',
                    },
                },
            },
        },
    },
});

export default theme;
