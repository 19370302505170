import React from 'react';
import { Text, Button, ButtonProps } from '@chakra-ui/react';

type Props = {
    tekst: string;
};

const PrimarKnapp = ({ tekst, ...buttonProps }: Props & ButtonProps) => {
    return (
        <Button variant="primær" {...buttonProps}>
            <Text className="b1">{tekst}</Text>
        </Button>
    );
};

export default PrimarKnapp;
