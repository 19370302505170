import React from 'react';
import { Flex, Text, Link } from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';
import PrimarKnapp from '../../komponenter/PrimarKnapp';

const AdminSide = () => {
    return (
        <Flex direction="column" align="center" mt="20">
            <Text as="h2">Adminsiden</Text>
            <Link
                as={ReactRouterLink}
                to={'/admin/utfordring/opprett'}
                style={{ textDecoration: 'none' }}
                mt="5"
            >
                <PrimarKnapp tekst={'Opprett ny utfordring'} />
            </Link>
            <Link
                as={ReactRouterLink}
                to={'/admin/utfordring'}
                style={{ textDecoration: 'none' }}
                mt="5"
            >
                <PrimarKnapp tekst={'Se alle utfordringer'} />
            </Link>
        </Flex>
    );
};

export default AdminSide;
