import React from 'react';
import { Image, ImageProps } from '@chakra-ui/react';
import { DELTAGERBILDE_BASE_URL } from '../api/api';

type Props = {
    ansattnr: number;
};

const DeltakerBilde = ({ ansattnr, ...ImageProps }: Props & ImageProps) => {
    return (
        <Image
            src={DELTAGERBILDE_BASE_URL + ansattnr}
            alt="deltakerbilde"
            borderRadius={500}
            objectPosition="center top"
            objectFit="cover"
            {...ImageProps}
        />
    );
};

export default DeltakerBilde;
