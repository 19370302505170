import React, { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Toppliste from './sider/toppliste/Toppliste';
import AdminSide from './sider/admin/Admin';
import OpprettUtfordring from './sider/admin/utfordring/OpprettUtfordring';
import Typografi from './sider/Typografi';
import Utfordringer from './sider/utfordringer/Utfordringer';
import Navigasjonsbar from './komponenter/Navigasjonsbar';
import UtfordringAdmin from './sider/admin/utfordring/UtfordringAdmin';
import RedigerUtfordring from './sider/admin/utfordring/RedigerUtfordring';
import AlleUtfordringerAdmin from './sider/admin/utfordring/AlleUtfordringerAdmin';
import { AnsatteProvider } from './context/AnsatteContext';
import KommerSnartSide from './sider/KommerSnartSide';
import { erAdmin } from './api/adminApi';
import { ForslagTilUtfordring } from './sider/ForslagTilUtfordring';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import useAnsattnummer from './hooks/useAnsattnummer';

function App() {
    const [admin, setAdmin] = useState<boolean>(false);

    const sjekkAdmin = async () => {
        const ansattnummer = await useAnsattnummer();
        const erBrukerAdmin = await erAdmin(ansattnummer);
        setAdmin(erBrukerAdmin);
    };

    if (
        !(window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') &&
        process.env.REACT_APP_LANSERT !== 'true'
    ) {
        return <KommerSnartSide />;
    }

    sjekkAdmin();

    return (
        <BrowserRouter>
            <AnsatteProvider>
                <Navigasjonsbar admin={admin} />
                <Routes>
                    {admin && (
                        <>
                            <Route path="/admin" element={<AdminSide />} />
                            <Route path="/admin/utfordring" element={<AlleUtfordringerAdmin />} />

                            <Route path="/admin/utfordring/:id" element={<UtfordringAdmin />} />
                            <Route
                                path="/admin/utfordring/opprett"
                                element={<OpprettUtfordring />}
                            />
                            <Route
                                path="/admin/utfordring/:id/rediger"
                                element={<RedigerUtfordring />}
                            />
                            <Route path="/typografi" element={<Typografi />} />
                        </>
                    )}
                    <Route path="/" element={<Toppliste />} />
                    <Route path="/utfordringer" element={<Utfordringer />} />
                    <Route path="/forslag-til-utfordring" element={<ForslagTilUtfordring />} />
                </Routes>
            </AnsatteProvider>
        </BrowserRouter>
    );
}

export default withAuthenticationRequired(App);
