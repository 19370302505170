import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { ITopplisteDeltaker } from '../../../typer';
import DeltakerBilde from '../../../komponenter/DeltakerBilde';
import Konfetti from '../../../komponenter/ikoner/Konfetti';

interface ledendeDeltakerProps {
    deltaker: ITopplisteDeltaker;
}

const LedendeDeltaker = ({ deltaker }: ledendeDeltakerProps) => {
    return (
        <Flex direction="column" w="100%" align="center" py={9} gap={1}>
            <Flex justifyContent="center" alignItems="center">
                <Flex zIndex={2} pos="absolute" mt={10}>
                    <DeltakerBilde w={170} h={170} ansattnr={deltaker.ansattnr} />
                </Flex>
                <Flex zIndex={1} maxW="350px">
                    <Konfetti />
                </Flex>
            </Flex>
            <Text className="b-uthevet">{deltaker.navn}</Text>
            <Text className="b1">{deltaker.poengTotalt} poeng</Text>
        </Flex>
    );
};

export default LedendeDeltaker;
