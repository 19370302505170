import React, { useEffect, useState } from 'react';
import { Flex, Link, Text } from '@chakra-ui/react';
import { IUtfordring } from '../../../typer';
import { hentUtfordring, redigerUtfordring } from '../../../api/utfordringerApi';
import UtfordringSkjema from '../komponenter/UtfordringSkjema';
import PrimarKnapp from '../../../komponenter/PrimarKnapp';
import { useMediaQuery } from 'usehooks-ts';
import { maksBredde } from '../../../konfigurasjoner';
import FinnesIkke from '../komponenter/FinnesIkke';
import { Link as ReactRouterLink, useParams } from 'react-router-dom';

const RedigerUtfordring = () => {
    const params = useParams();

    if (params.id === undefined) return null;

    const utfordringId = parseInt(params.id);
    const skjermBredde = useMediaQuery(maksBredde);

    const [utfordring, settUtfordring] = useState<IUtfordring>({
        id: -1,
        sesong: '',
        tittel: '',
        dato: '',
        sted: '',
        beskrivelse: '',
    });

    useEffect(() => {
        const hentData = async (id: number) => {
            const utfordring = await hentUtfordring(id);
            if (utfordring !== undefined) settUtfordring(utfordring);
        };

        if (utfordringId !== undefined) {
            hentData(utfordringId);
        }
    }, [utfordringId]);

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        settUtfordring({ ...utfordring, [event.target.name]: event.target.value });
    };

    const registrer = () => {
        if (utfordring.id >= 0) {
            redigerUtfordring(utfordring);
        }
    };

    if (utfordring.tittel !== '') {
        return (
            <Flex direction="column" w={skjermBredde ? '85%' : '50vw'} m="auto" gap={5} py={10}>
                <Text as="h2">Rediger utfordring {utfordringId}</Text>
                <UtfordringSkjema utfordring={utfordring} handleChange={handleChange} />
                <Link
                    alignSelf="center"
                    as={ReactRouterLink}
                    to={`/admin/utfordring/${utfordringId}`}
                >
                    <PrimarKnapp onClick={registrer} tekst={'Rediger utfordring'} />
                </Link>
            </Flex>
        );
    }
    return (
        <FinnesIkke
            tekst="Buhuuuu, denne utfordringen finnes ikke 👹"
            path="/admin/utfordringer"
            knappTekst="Se alle utfordringer"
        />
    );
};

export default RedigerUtfordring;
