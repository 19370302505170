import { ITopplisteDeltaker, IRestDeltakelse, TopplisteInnsats } from '../typer';

export const hentInnsatsRankMap = (deltakere: IRestDeltakelse[]) => {
    const poeng = deltakere
        .map((d) => {
            return d.poeng;
        })
        .sort((a, b) => b - a);

    return poeng.reduce(
        (map, poeng, i) => (map[poeng] ? map : { ...map, [poeng]: i }),
        {} as { [poeng: string]: number }
    );
};

const beregnPoengTotalt = (innsatser: TopplisteInnsats[]) =>
    innsatser.reduce((sum, innsats) => sum + innsats.poeng + innsats.bonuspoeng, 0);

export const lagToppliste = (deltakere: {
    [ansattnr: number]: ITopplisteDeltaker;
}): ITopplisteDeltaker[] => {
    const toppliste = Object.values(deltakere)
        .map((deltaker) => ({
            ...deltaker,
            poengTotalt: beregnPoengTotalt(Object.values(deltaker.innsatser)),
            antallUtfordringer: Object.values(deltaker.innsatser).length,
        }))
        .sort((a, b) => b.poengTotalt - a.poengTotalt);

    let lastNumPoints = 0;
    for (let i = 0; i < toppliste.length; i++) {
        const deltaker = toppliste[i];
        if (i !== 0 && deltaker.poengTotalt === lastNumPoints) {
            deltaker.rank = toppliste[i - 1].rank;
        } else {
            deltaker.rank = i + 1;
            lastNumPoints = deltaker.poengTotalt;
        }
    }

    return toppliste;
};
