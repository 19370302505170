import React from 'react';
import { useMediaQuery } from 'usehooks-ts';
import { Flex } from '@chakra-ui/react';
import MobilMeny from './MobilMeny';
import { maksBredde } from '../konfigurasjoner';
import NavbarKnapp from './NavbarKnapp';

type Props = {
    admin: boolean;
};

const Navigasjonsbar = ({ admin }: Props) => {
    const skjermBredde = useMediaQuery(maksBredde);

    return (
        <Flex h={20} justify="space-between" align="center" px={5}>
            <NavbarKnapp href="/" tekst="UTFORDRINGEN" />
            {skjermBredde ? (
                <MobilMeny admin={admin} />
            ) : (
                <Flex gap={5}>
                    <NavbarKnapp href="/" tekst="Toppliste" />
                    <NavbarKnapp href="/utfordringer" tekst="Utfordringer" />
                    <NavbarKnapp href="/forslag-til-utfordring" tekst="Forslag til utfordring" />
                    {admin && <NavbarKnapp href="/admin" tekst="Admin" />}
                </Flex>
            )}
        </Flex>
    );
};

export default Navigasjonsbar;
