import { IRestUtfordringDeltakelse, IUtfordringDeltakelse } from '../typer';
import { supabase } from './supabaseClient';

export const hentToppliste = async (): Promise<Record<number, number[]> | undefined> => {
    try {
        const { data, error } = await supabase.from('toppliste').select('*');

        if (error) throw error;

        return data.reduce((acc, deltaker) => {
            const { ansattnr, poeng } = deltaker;
            return { ...acc, [poeng]: [...(acc[poeng] || []), ansattnr] };
        }, {} as Record<number, number>);
    } catch (error) {
        console.log(error);
    }
};

export const hentTopplisteUtvidet = async (): Promise<IUtfordringDeltakelse[] | undefined> => {
    try {
        const { data, error } = await supabase.from('utfordring').select(`
          id,
          sesong,
          dato,
          tittel,
          sted,
          beskrivelse,
          deltakelse (
            utfordringId,
            ansattnr,
            poeng,
            bonuspoeng
          )
        `);

        if (error) throw error;

        return data.map((utfordring: IRestUtfordringDeltakelse) => {
            return {
                ...utfordring,
                deltakelse: utfordring.deltakelse
                    ? Array.isArray(utfordring.deltakelse)
                        ? utfordring.deltakelse
                        : [utfordring.deltakelse]
                    : [],
            };
        });
    } catch (error) {
        console.log(error);
    }
};
