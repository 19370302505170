import React from 'react';
import { Text, Menu, MenuButton, MenuList, MenuItem, IconButton, Link } from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';
import HamburgerMeny from './ikoner/HamburgerMeny';

type Props = {
    admin: boolean;
};

const MobilMeny = ({ admin }: Props) => {
    return (
        <Menu>
            <MenuButton
                variant="unstyled"
                isRound="true"
                as={IconButton}
                aria-label="meny"
                icon={<HamburgerMeny />}
            />
            <MenuList zIndex="999">
                <Link as={ReactRouterLink} to="/">
                    <MenuItem>
                        <Text className="navbar-tekst">Toppliste</Text>
                    </MenuItem>
                </Link>
                <Link as={ReactRouterLink} to="/utfordringer">
                    <MenuItem>
                        <Text className="navbar-tekst">Utfordringer</Text>
                    </MenuItem>
                </Link>
                <Link as={ReactRouterLink} to="/forslag-til-utfordring">
                    <MenuItem>
                        <Text className="navbar-tekst">Forslag til utfordring</Text>
                    </MenuItem>
                </Link>
                {admin && (
                    <Link as={ReactRouterLink} to="/admin">
                        <MenuItem>
                            <Text className="navbar-tekst">Admin</Text>
                        </MenuItem>
                    </Link>
                )}
            </MenuList>
        </Menu>
    );
};

export default MobilMeny;
