import { Flex, Text } from '@chakra-ui/react';
import React from 'react';

const Typografi = () => {
    return (
        <Flex direction="column">
            <Text as="h1">Heading 1 - H1</Text>
            <Text as="h2">Heading 2 - H2</Text>
            <Text as="h3">Heading 3 - H3</Text>
            <Text as="h4">Heading 4 - H4</Text>
            <Text as="h5">Heading 5 - H3</Text>
            <Text className="b-uthevet">Dette er en brødtekst i b-uthevet</Text>
            <Text className="b1">Dette er en brødtekst i b1</Text>
            <Text className="b2">Dette er en brødtekst i b1</Text>
            <Text className="navbar-tekst">Navbar-tekst</Text>
        </Flex>
    );
};

export default Typografi;
