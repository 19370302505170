import React, { useState } from 'react';
import { Flex, Text, useMediaQuery } from '@chakra-ui/react';
import PrimarKnapp from '../komponenter/PrimarKnapp';
import { maksBredde } from '../konfigurasjoner';
import Inputfelt from './admin/komponenter/Inputfelt';
import { IUtfordringForslag } from '../typer';
import { sendInnForslagTilUtfordring } from '../api/utfordringerApi';
import Textareafelt from './admin/komponenter/Textareafelt';
import useAnsattnummer from '../hooks/useAnsattnummer';

export const ForslagTilUtfordring = () => {
    const mobilVisning = useMediaQuery(maksBredde)[0];

    const [forslagTilUtfordring, settForslagTilUtfordring] = useState<IUtfordringForslag>({
        utfordring: '',
        kommentar: '',
    });

    const sendInnForslag = async () => {
        const ansattnr = await useAnsattnummer();
        const innsendt = await sendInnForslagTilUtfordring(forslagTilUtfordring, ansattnr || 0);

        if (innsendt) {
            settForslagTilUtfordring({ utfordring: '', kommentar: '' });
        }
    };

    return (
        <Flex
            direction="column"
            w={mobilVisning ? '80vw' : '60vw'}
            m="auto"
            py={mobilVisning ? '10' : '20'}
            gap={8}
        >
            <Flex direction="column">
                <Text as="h4">Send inn ditt</Text>
                <Text as="h1" alignSelf="center">
                    FORSLAG
                </Text>
                <Text as="h4" alignSelf="flex-end">
                    til neste utfordring
                </Text>
            </Flex>
            <form>
                <Inputfelt
                    label="Utfordring"
                    name="tittel"
                    value={forslagTilUtfordring.utfordring}
                    handleChange={(e) =>
                        settForslagTilUtfordring((forrigeState) => ({
                            ...forrigeState,
                            utfordring: e.target.value,
                        }))
                    }
                />
                <Textareafelt
                    label="Kommentar"
                    name="kommentar"
                    value={forslagTilUtfordring.kommentar}
                    handleChange={(e) =>
                        settForslagTilUtfordring((forrigeState) => ({
                            ...forrigeState,
                            kommentar: e.target.value,
                        }))
                    }
                />
            </form>
            <PrimarKnapp onClick={sendInnForslag} tekst="Send inn forslag" />
        </Flex>
    );
};
