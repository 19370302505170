import { getConfig } from '../utils/config';

/* --- Lenker --- */
export const DELTAGERBILDE_BASE_URL =
    'https://res.cloudinary.com/bekkimg/w_356,h_448,c_fill/d_default_image_departmentId2.png/';

interface IRequest {
    path: string;
    token: string;
    body?: string;
}

export const get = ({ path, token }: IRequest) =>
    hentOgValider('GET', path, token)
        .then((res) => res.text())
        .then((text) => (text.length ? JSON.parse(text) : undefined));

const options = (method: string, token: string, body?: string) => ({
    method,
    headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
    },
    body,
});
('');

export async function hentOgValider(method: string, path: string, token: string, body?: string) {
    const url = `${getConfig().bekkApiUrl}${path}`;

    const response = await fetch(url, options(method, token, body));

    if (response.ok) {
        return response;
    }

    // TODO: Tilpass denne
    const {
        userMessage = `Noe har feilet i et kall til ${method} ${url} med statuskode ${
            response.status
        }${(body && ` og body ${body}`) || ''}`,
    } = await response.json().catch(() => ({}));

    // TODO: Send varsling/alert
    return Promise.reject(userMessage);
}
