export interface Config {
    clientId: string;
    audience: string;
    issuerDomain: string;
    scopes: string;
    environment: string;
    bekkApiUrl: string;
}

const isDevHostname = () => {
    switch (window.location.hostname) {
        case 'localhost':
        case '127.0.0.1':
        case 'utfordringen-dev.herokuapp.com':
            return true;
        default:
            return false;
    }
};

export function getConfig(): Config {
    const devConfig: Config = {
        clientId: 'QHQy75S7tmnhDdBGYSnszzlhMPul0fAE',
        audience: 'https://api.bekk.dev',
        issuerDomain: 'bekk-dev.eu.auth0.com',
        scopes: 'openid name groups',
        bekkApiUrl: 'https://api.bekk.dev/employee-svc',
        environment: 'development',
    };

    const prodConfig: Config = {
        clientId: 'HuH7oGHSgymn4mYLzEClyE2bhQSM1iTC',
        audience: 'https://api.bekk.no',
        issuerDomain: 'bekk.eu.auth0.com',
        scopes: 'openid name groups',
        bekkApiUrl: 'https://api.bekk.no/employee-svc',
        environment: 'production',
    };
    if (isDevHostname()) {
        return devConfig;
    } else {
        return prodConfig;
    }
}
