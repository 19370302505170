import { FormControl, FormLabel, Textarea } from '@chakra-ui/react';
import React from 'react';

interface Props {
    label: string;
    name: string;
    value: string;
    handleChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const Textareafelt = ({ label, name, value, handleChange }: Props) => {
    return (
        <FormControl mt="10">
            <FormLabel className="b1">{label}</FormLabel>
            <Textarea className="b2" name={name} value={value} onChange={(e) => handleChange(e)} />
        </FormControl>
    );
};

export default Textareafelt;
