export const formaterDatoTilDatabase = (dato: Date) => {
    const aar = dato.getFullYear();
    const maaned = dato.getMonth() + 1;
    const dag = dato.getDate();
    return aar + '-' + maaned + '-' + dag;
};

const maaneder = [
    'januar',
    'februar',
    'mars',
    'april',
    'mai',
    'juni',
    'juli',
    'august',
    'september',
    'oktober',
    'november',
    'desember',
];

const ukedager = ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'];

export const lagDatoStreng = (datoStreng: string): string => {
    const dato = new Date(datoStreng);
    const maaned = maaneder[dato.getMonth()];
    const ukedag = ukedager[dato.getDay()];
    return ukedag + ' ' + dato.getDate() + '. ' + maaned + ' ' + dato.getFullYear();
};
