import React from 'react';
import { INyUtfordring } from '../../../typer';
import DatoInputfelt from './DatoInputfelt';
import Inputfelt from './Inputfelt';
import Textareafelt from './Textareafelt';

interface Props {
    utfordring: INyUtfordring;
    handleChange: (
        e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>
    ) => void;
}

const UtfordringSkjema = ({ utfordring, handleChange }: Props) => {
    return (
        <form>
            <Inputfelt
                label="Tittel"
                name="tittel"
                value={utfordring.tittel}
                handleChange={handleChange}
            />
            <Inputfelt
                label="Sesong"
                name="sesong"
                value={utfordring.sesong}
                handleChange={handleChange}
            />
            <DatoInputfelt
                label="Dato"
                name="dato"
                value={utfordring.dato}
                handleChange={handleChange}
            />
            <Inputfelt
                label="Sted"
                name="sted"
                value={utfordring.sted}
                handleChange={handleChange}
            />
            <Textareafelt
                label="Beskrivelse"
                name="beskrivelse"
                value={utfordring.beskrivelse}
                handleChange={handleChange}
            />
        </form>
    );
};

export default UtfordringSkjema;
