import React, { useState } from 'react';

import { Tr, Td, Input } from '@chakra-ui/react';
import { IDeltaker } from '../../../typer';
import PrimarKnapp from '../../../komponenter/PrimarKnapp';
import { redigerPoeng } from '../../../api/deltakerApi';

interface Props {
    deltaker: IDeltaker;
    utfordringId: number;
    oppdaterDeltakerliste: (oppdatertDeltaker: IDeltaker) => void;
}

const DeltakerRad = ({ deltaker, utfordringId, oppdaterDeltakerliste }: Props) => {
    const [redigeringsmodus, settRedigeringsmodus] = useState<boolean>(false);
    const [redigertePoeng, settRedigertePoeng] = useState<{ poeng: number; bonuspoeng: number }>({
        poeng: deltaker.poeng,
        bonuspoeng: deltaker.bonuspoeng,
    });

    const lagrePoeng = async () => {
        const redigertDeltaker = await redigerPoeng(
            redigertePoeng.poeng,
            redigertePoeng.bonuspoeng,
            deltaker.ansattnr,
            utfordringId
        );

        if (redigertDeltaker !== undefined) {
            oppdaterDeltakerliste({
                ...deltaker,
                poeng: redigertDeltaker.poeng,
                bonuspoeng: redigertePoeng.bonuspoeng,
            });
            settRedigeringsmodus(false);
        }
    };

    const startRedigering = () => {
        settRedigertePoeng({ poeng: deltaker.poeng, bonuspoeng: deltaker.bonuspoeng || 0 });
        settRedigeringsmodus(true);
    };

    if (redigeringsmodus) {
        return (
            <Tr>
                <Td>{deltaker.navn}</Td>
                <Td>
                    <Input
                        w={20}
                        className="b2"
                        name="poeng"
                        value={redigertePoeng.poeng}
                        type="number"
                        onChange={(e) =>
                            settRedigertePoeng({
                                ...redigertePoeng,
                                poeng: parseInt(e.target.value),
                            })
                        }
                    />
                </Td>
                <Td>
                    <Input
                        w={20}
                        className="b2"
                        name="bonuspoeng"
                        value={redigertePoeng.bonuspoeng}
                        type="number"
                        onChange={(e) =>
                            settRedigertePoeng({
                                ...redigertePoeng,
                                bonuspoeng: parseInt(e.target.value),
                            })
                        }
                    />
                </Td>
                <Td>
                    <PrimarKnapp tekst="Lagre poeng" onClick={lagrePoeng} />
                    <PrimarKnapp tekst="Avbryt" onClick={() => settRedigeringsmodus(false)} />
                </Td>
            </Tr>
        );
    }

    return (
        <Tr>
            <Td>{deltaker.navn}</Td>
            <Td>{deltaker.poeng}</Td>
            <Td>{deltaker.bonuspoeng}</Td>
            <Td>
                <PrimarKnapp tekst="Rediger poeng" onClick={startRedigering} />
            </Td>
        </Tr>
    );
};

export default DeltakerRad;
