import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme/theme';
import { Auth0Provider } from '@auth0/auth0-react';
import { getConfig } from './utils/config';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const config = getConfig();
root.render(
    <ChakraProvider theme={theme}>
        <React.StrictMode>
            <Auth0Provider
                domain={config.issuerDomain}
                clientId={config.clientId}
                cacheLocation="localstorage"
                authorizationParams={{
                    redirect_uri: window.location.origin,
                    scope: config.scopes,
                    audience: config.audience,
                }}
            >
                <App />
            </Auth0Provider>
        </React.StrictMode>
    </ChakraProvider>
);
