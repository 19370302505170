import React from 'react';
import { Flex, Select } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useAnsatte } from '../../context/AnsatteContext';
import { ITopplisteDeltaker, IUtfordringDeltakelse } from '../../typer';
import { hentInnsatsRankMap, lagToppliste } from '../../utils/toppliste';
import { hentTopplisteUtvidet } from '../../api/topplisteApi';
import TopplisteUtvidet from './TopplisteUtvidet';
import TopplisteTotal from './TopplisteTotal';

enum EToppliste {
    TOPPLISTE_TOTAL = 'TOPPLISTE_TOTAL',
    TOPPLISTE_UTVIDET = 'TOPPLISTE_UTVIDET',
}

const Toppliste = () => {
    const [fullToppliste, settFullToppliste] = useState<IUtfordringDeltakelse[]>();
    const [toppliste, settToppliste] = useState<ITopplisteDeltaker[]>();
    const [topplisteSomSkalVises, settTopplisteSomSkalVises] = useState<EToppliste>(
        EToppliste.TOPPLISTE_TOTAL
    );
    const [sesonger, settSesonger] = useState<string[]>([]);
    const [valgtSesong, settValgtSesong] = useState<string>('h23');

    const { alleAnsatte, finnNavnPåAnsatt } = useAnsatte();

    const genererToppliste = (utfordringInnsatser: IUtfordringDeltakelse[], sesong: string) => {
        const deltakere: { [ansattnr: number]: ITopplisteDeltaker } = {};

        const utfordringInnsatserISesong = utfordringInnsatser.filter(
            (utfordring) => utfordring.sesong === sesong
        );

        utfordringInnsatserISesong.forEach((utfordringInnsats) => {
            utfordringInnsats.deltakelse.forEach((deltaker) => {
                deltakere[deltaker.ansattnr] = {
                    navn: finnNavnPåAnsatt(deltaker.ansattnr) || '',
                    ansattnr: deltaker.ansattnr,
                    innsatser: {},
                    poengTotalt: 0,
                    rank: 0,
                    antallUtfordringer: 0,
                };
            });
        });

        utfordringInnsatserISesong.map((utfordringInnsats) => {
            const innsatsRankMap = hentInnsatsRankMap(utfordringInnsats.deltakelse);

            return utfordringInnsats.deltakelse.map((deltaker) => {
                const rank = innsatsRankMap[deltaker.poeng];

                return (deltakere[deltaker.ansattnr].innsatser[utfordringInnsats.id] = {
                    poeng: deltaker.poeng,
                    bonuspoeng: deltaker.bonuspoeng,
                    utfordringRank: rank + 1,
                });
            });
        });

        return lagToppliste(deltakere);
    };

    useEffect(() => {
        const hentData = async () => {
            const utvidetToppliste = await hentTopplisteUtvidet();

            if (utvidetToppliste) {
                settSesonger(
                    Array.from(new Set(utvidetToppliste.map((utfordring) => utfordring.sesong)))
                );
                settFullToppliste(utvidetToppliste);
            }
        };

        hentData();
    }, [alleAnsatte]);

    useEffect(() => {
        if (fullToppliste) {
            settToppliste(genererToppliste(fullToppliste, valgtSesong));
        }
    }, [fullToppliste, valgtSesong]);

    const byttTopplisteVisning = (valg: string) => {
        if (valg === EToppliste.TOPPLISTE_UTVIDET)
            settTopplisteSomSkalVises(EToppliste.TOPPLISTE_UTVIDET);
        else settTopplisteSomSkalVises(EToppliste.TOPPLISTE_TOTAL);
    };

    return (
        <Flex direction="column" w="100vw" align="center" px="5vw" py="3vw">
            <Flex justify="flex-end" gap={2} w="100%">
                <Select
                    onChange={(e) => settValgtSesong(e.target.value)}
                    w={'max-content'}
                    value={valgtSesong}
                >
                    {sesonger.map((sesong, indeks) => (
                        <option value={sesong} key={indeks}>
                            {sesong}
                        </option>
                    ))}
                </Select>
                <Select
                    onChange={(e) => byttTopplisteVisning(e.target.value)}
                    w={'max-content'}
                    defaultValue={EToppliste.TOPPLISTE_TOTAL}
                >
                    <option value={EToppliste.TOPPLISTE_TOTAL}>Total toppliste</option>
                    <option value={EToppliste.TOPPLISTE_UTVIDET}>Utvidet toppliste</option>
                </Select>
            </Flex>
            {toppliste &&
                (topplisteSomSkalVises === EToppliste.TOPPLISTE_TOTAL ? (
                    <TopplisteTotal toppliste={toppliste} />
                ) : (
                    <TopplisteUtvidet toppliste={toppliste} valgtSesong={valgtSesong} />
                ))}
        </Flex>
    );
};

export default Toppliste;
