import { menuAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
    menuAnatomy.keys
);

const baseStyle = definePartsStyle({
    list: {
        bg: 'var(--svart)',
        border: 'var(--overskyet) solid 1px',
    },
    item: {
        color: 'var(--hvit)',
        _hover: {
            bg: 'var(--overskyet)',
            color: 'var(--svart)',
        },
        _focus: {
            bg: 'none',
        },
    },
});

export const menuTheme = defineMultiStyleConfig({ baseStyle });
