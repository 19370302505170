import React, { useState } from 'react';
import { Flex, Link, Text } from '@chakra-ui/react';
import { INyUtfordring } from '../../../typer';
import { opprettUtfordring } from '../../../api/utfordringerApi';
import UtfordringSkjema from '../komponenter/UtfordringSkjema';
import PrimarKnapp from '../../../komponenter/PrimarKnapp';
import { useMediaQuery } from 'usehooks-ts';
import { maksBredde } from '../../../konfigurasjoner';
import { Link as ReactRouterLink } from 'react-router-dom';

const OpprettUtfordring = () => {
    const mobilVisning = useMediaQuery(maksBredde);
    const [nyUtfordring, settNyUtfordring] = useState<INyUtfordring>({
        sesong: '',
        tittel: '',
        dato: '',
        sted: '',
        beskrivelse: '',
    });

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        settNyUtfordring({ ...nyUtfordring, [event.target.name]: event.target.value });
    };

    const registrer = async () => {
        const registrert = await opprettUtfordring(nyUtfordring);
        console.log(registrert);
        alert('Utfordring registrert🎉');
    };

    return (
        <Flex
            direction="column"
            w={mobilVisning ? '80vw' : '50vw'}
            m="auto"
            py={mobilVisning ? '10' : '20'}
            gap={10}
        >
            <Text as="h2">Registrer ny utfordring</Text>
            <UtfordringSkjema utfordring={nyUtfordring} handleChange={handleChange} />
            <Link as={ReactRouterLink} to="/admin" alignSelf="center">
                <PrimarKnapp onClick={registrer} tekst="Registrer utfordring" />
            </Link>
        </Flex>
    );
};

export default OpprettUtfordring;
