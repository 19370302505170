import { INyUtfordring, IUtfordring, IUtfordringForslag } from '../typer';
import { formaterDatoTilDatabase } from '../utils/datoUtils';
import { supabase } from './supabaseClient';

export const hentUtfordring = async (id: number): Promise<IUtfordring | undefined> => {
    try {
        const { data, error } = await supabase.from('utfordring').select('*').eq('id', id).single();

        if (error) throw error;

        return data;
    } catch (error) {
        console.log(error);
    }
};

export const hentAlleUtfordringer = async (): Promise<IUtfordring[] | undefined> => {
    try {
        const { data, error } = await supabase
            .from('utfordring')
            .select('*')
            .order('dato', { ascending: true });

        if (error) throw error;

        return data;
    } catch (error) {
        console.log(error);
    }
};

export const hentTidligereUtfordringer = async (): Promise<IUtfordring[] | undefined> => {
    try {
        const { data, error } = await supabase
            .from('utfordring')
            .select('*')
            .lt('dato', formaterDatoTilDatabase(new Date()))
            .order('dato', { ascending: false });

        if (error) throw error;

        return data;
    } catch (error) {
        console.log(error);
    }
};

export const opprettUtfordring = async (
    nyUtfordring: INyUtfordring
): Promise<IUtfordring | undefined> => {
    try {
        const { data, error } = await supabase
            .from('utfordring')
            .insert({
                tittel: nyUtfordring.tittel,
                sesong: nyUtfordring.sesong,
                dato: nyUtfordring.dato,
                sted: nyUtfordring.sted,
                beskrivelse: nyUtfordring.beskrivelse,
            })
            .select()
            .single();

        if (error) throw error;

        return data;
    } catch (error) {
        console.log(error);
    }
};

export const redigerUtfordring = async (
    redigertUtfordring: IUtfordring
): Promise<boolean | undefined> => {
    try {
        const { error } = await supabase
            .from('utfordring')
            .update({
                tittel: redigertUtfordring.tittel,
                dato: redigertUtfordring.dato,
                sted: redigertUtfordring.sted,
                beskrivelse: redigertUtfordring.beskrivelse,
            })
            .eq('id', redigertUtfordring.id);

        if (error) throw error;

        alert('Redigert🔥');
        return true;
    } catch (error) {
        console.log(error);
        alert('Error bitch!');
    }
};

export const sendInnForslagTilUtfordring = async (
    forslagTilUtfordring: IUtfordringForslag,
    ansattnr: number
): Promise<boolean | undefined> => {
    try {
        const { error } = await supabase.from('forslag').insert({
            utfordring: forslagTilUtfordring.utfordring,
            kommentar: forslagTilUtfordring.kommentar,
            ansattnr: ansattnr,
        });

        if (error) throw error;

        alert('Takk for ditt forslag! Send gjerne inn flere 🔥');
        return true;
    } catch (error) {
        alert(`Noe gikk galt under innsending av forslag🥲: ${error}`);
    }
};
